@import "./sass/mixins";

html, body {
  @extend %hard-reset;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: get-color(primary-background);
}

// @font-face {
//   font-family: 'Gilroy';
//   src: url('./assets/Gilroy/gilroy_regular.otf') format('eot'),
//     url('./assets/Gilroy/gilroy_regular.otf') format('woff'),
//     url('./assets/Gilroy/gilroy_regular.otf') format('truetype'),
//     url('./assets/Gilroy/gilroy_regular.otf') format('opentype');
// };

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-Thin.ttf') format('eot'),
    url('./assets/Inter/Inter-Thin.ttf') format('woff'),
    url('./assets/Inter/Inter-Thin.ttf') format('truetype'),
    url('./assets/Inter/Inter-Thin.ttf') format('opentype');
  font-weight: 100;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-ExtraLight.ttf') format('eot'),
    url('./assets/Inter/Inter-ExtraLight.ttf') format('woff'),
    url('./assets/Inter/Inter-ExtraLight.ttf') format('truetype'),
    url('./assets/Inter/Inter-ExtraLight.ttf') format('opentype');
  font-weight: 200;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-Light.ttf') format('eot'),
    url('./assets/Inter/Inter-Light.ttf') format('woff'),
    url('./assets/Inter/Inter-Light.ttf') format('truetype'),
    url('./assets/Inter/Inter-Light.ttf') format('opentype');
  font-weight: 300;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-Regular.ttf') format('eot'),
    url('./assets/Inter/Inter-Regular.ttf') format('woff'),
    url('./assets/Inter/Inter-Regular.ttf') format('truetype'),
    url('./assets/Inter/Inter-Regular.ttf') format('opentype');
  font-weight: 400;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-Medium.ttf') format('eot'),
    url('./assets/Inter/Inter-Medium.ttf') format('woff'),
    url('./assets/Inter/Inter-Medium.ttf') format('truetype'),
    url('./assets/Inter/Inter-Medium.ttf') format('opentype');
  font-weight: 500;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-SemiBold.ttf') format('eot'),
    url('./assets/Inter/Inter-SemiBold.ttf') format('woff'),
    url('./assets/Inter/Inter-SemiBold.ttf') format('truetype'),
    url('./assets/Inter/Inter-SemiBold.ttf') format('opentype');
  font-weight: 600;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-Bold.ttf') format('eot'),
    url('./assets/Inter/Inter-Bold.ttf') format('woff'),
    url('./assets/Inter/Inter-Bold.ttf') format('truetype'),
    url('./assets/Inter/Inter-Bold.ttf') format('opentype');
  font-weight: 700;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-ExtraBold.ttf') format('eot'),
    url('./assets/Inter/Inter-ExtraBold.ttf') format('woff'),
    url('./assets/Inter/Inter-ExtraBold.ttf') format('truetype'),
    url('./assets/Inter/Inter-ExtraBold.ttf') format('opentype');
  font-weight: 800;
};

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-Black.ttf') format('eot'),
    url('./assets/Inter/Inter-Black.ttf') format('woff'),
    url('./assets/Inter/Inter-Black.ttf') format('truetype'),
    url('./assets/Inter/Inter-Black.ttf') format('opentype');
  font-weight: 900;
};