@import "variables";

%hard-reset{
  *,
  *::before,
  *::after{box-sizing:border-box;}
  a{text-decoration:none; color:inherit; cursor:pointer;}
  button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
  figure{margin:0;}
  input::-moz-focus-inner {border:0; padding:0; margin:0;}
  ul, ol, dd{margin:0; padding:0; list-style:none;}
  h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
  p{margin:0;}
  cite {font-style:normal;}
  fieldset{border-width:0; padding:0; margin:0;}
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: inset 0 0 0 1000px get-color(primary-background);
    transition: background-color 5000s ease-in-out 0s;
  }
}

%nav-section-title {
  font-family: $primary-font;
  font-weight: 800;
  font-size: 28px;
  color: get-color(text-primary);
}

@mixin dashboardBullet($section-name) {
  background-color: get-color($section-name);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  color: get-color(primary-background);
  font-family: $primary-font;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

//Buttons Styles
@mixin rounded--button($background: null, $text-color:input-text, $border-color:null, $font-weight: null) {
  color: get-color($text-color);
  font-family: $primary-font;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0 21px;
  line-height: normal;
  @if $font-weight != null {
    font-weight: $font-weight;
  }
  @if $background != null {
    background-color: get-color($background);
  }
  @if $border-color != null {
    border: 1px solid get-color($border-color);
  }
  &:focus {
    outline: none;
  }
}

@mixin rounded-input($background: primary-background,$border-color: grey-border, $color: black, $font-weight: normal) {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: $font-weight;
  color: $color;
  background-color: get-color($background);
  outline: none;
  border: 1px solid get-color($border-color);
  border-radius: 3px;
  padding: 6px 0 6px 12px;
  height: 33px;
  width: 100%;
  &:focus {
    outline: none;
  }
}
%invalid-text-rounded-input{
  border-color: get-color(error) !important;
  color: get-color(error) !important;
  box-shadow: 0 0 2px get-color(error) !important;
}

%radio-button {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $primary-font;
  font-size: 12px;

  > input {
    display: none;
  }

  > i {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    border: 1px solid get-color(grey-border);
    box-shadow: inset 0 0 0 2.5px get-color(primary-background);
    transition: .25s;
  }

  > input:checked + i {
    border-color: get-color(primary-blue);
    background: get-color(primary-blue);
  }

  &:hover {
    cursor: pointer;
  }
}

%flex-card {
  display: flex;
  background: get-color(primary-background);
  box-shadow: 0 4px 10px get-color(secondary-background);

  > * {
    font-family: $primary-font;
    line-height: normal !important;
  }
}

@mixin transition($duration) {
  -webkit-transition: $duration;
  -moz-transition: $duration;
  -ms-transition: $duration;
  -o-transition: $duration;
  transition: $duration;
}

// User details view cards common styles
%card-field {
  max-width: 220px;
  font-size: 14px;
}

%card-field-title {
  @extend %card-field;
  max-width: 160px;
  font-weight: 500;
  color: get-color(input-text);
}

%card-field-value {
  @extend %card-field;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin rotate($deg) {
  transform: rotate($deg);
}
%card-field-value-empty {
  @extend %card-field;
  color: get-color(empty-field);
}

%pop-up-card {
  background: get-color(primary-background);
  border: 1px solid #F5F7FA;
  box-shadow: 0 4px 5px rgba(140, 155, 165, 0.25);
  border-radius: 4px;
  z-index: 999;
}


%ridesCancelModal {
  color: get-color(primary-black);

  h3 {
    padding: 1.5rem 0 1rem 1.5rem;
    font-weight: 800;
    font-size: 24px;
    border-bottom: 1px solid get-color(subtle-grey);
  }

  div {
    display: flex;
    justify-content: center;
    padding: 2.5rem 1.5rem 0 1.5rem;

    span {
      font-weight: 800;
      text-decoration: underline;
    }
  }
}

%stops-itinerary {
  min-width: 30%;
  margin-left: 0.5%;

  ul {
    display: block;
    margin-right: 3rem;
    list-style: none;

    li {
      border-left: 1px dashed get-color(text-grey);
      display: flex;
      flex-direction: column;
      padding: 0 0 10px 1rem;
      position: relative;
      top: 10px;

      &:last-child {
        margin: 0;
        border: unset;
      }

      &::before {
        content: "";
        position: absolute;
        left: -6px;
        width: 12px;
        height: 12px;
        border: 1px solid get-color(tahoe);
        border-radius: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        left: -5px;
        top: 1px;
        width: 10px;
        height: 10px;
        border: 1px solid get-color(primary-background);
        border-radius: 50%;
        background-color: get-color(tahoe);
      }

      span:first-child {
        color: get-color(primary-black);
        font-weight: 500;
        font-size: 18px;
        position: relative;
        top: -5px;
      }
      span:nth-child(2) {
        font-size: 1.2rem;
      }

      span:last-child {
        color: get-color(text-grey);
        font-weight: 500;
        font-size: 14px;
        position: relative;
      }
    }

    .dropOff {
      &:before {
        margin: 0;
        border: 1px solid get-color(poppy-reserve);
      }

      &:after {
        background-color: get-color(poppy-reserve);
      }
    }
  }
}
