$colors: (
        primary-background: #FFFFFF,
        content: #F5F5F5,
        secondary-background: #F0F3F6,
        primary-black: #252525,
        subtle-grey: #DBDBDB,
        text-grey: #9D9D9D,
        side-bar: #FAFAFA,
        side-menu: #F6F6F6,
        box-shadow-primary: rgba(0, 0, 0, 0.25),
        subtle-yellow: rgba(255, 188, 0, 0.2),
        error: #F55F44,
        success: #ADC851,
        alert: #F4C76F,
        text-primary: #152935,
        text-secondary: #333459,
        primary-blue: #5062FF,
        subtle-blue: rgba(80, 98, 255, 0.1),
        active-nav-link: rgba(223, 227, 230, 0.4),
        empty-field: rgba(140, 155, 165, 0.5),
        input-text: #8C9BA5,
        input-background: #F5F7FA,
        grey-border: #E5E5E5,
        subtle-grey-border: #F2F2F2,
        users: #FD6F8D,
        metrics: #FDA57D,
        drivers: #5062FF,
        rides: #F4C76F,
        snackbar-error: #EE6752,
        tahoe: #18A0FB,
        tahoe-light: lighten(#18A0FB, 40%),
        mauna-loa: #EB4962,
        mauna-loa-light: lighten(#EB4962, 30%),
        poppy-reserve: #EE6752,
        central-park: #50CC89,
);

@function get-color($color-name) {
  @return map-get($colors, $color-name);
}



// FONTS
$primary-font: 'Inter';

